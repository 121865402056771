import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewUser.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { addUserAdmin, addUserAdminAlt } from "../../services/usersService";
import { useEffect, useState } from "react";
import { searchParents } from "../../services/rolesService";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshUsers: () => void;
}

const NewUser: React.FC<Props> = ({ visible, closeModal, refreshUsers }) => {
  const userSlice: any = useAppSelector((state) => state.UserSlice);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);
  const [roles, setRoles] = useState<any>([])
  const [roleId, setRoleID] = useState<any>('')

  const handleInputFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleInputLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleInputMail = (e: any) => {
    setUserEmail(e.target.value);
  };

  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  async function addUser(firstName: string, lastName: string, email: string, phone: string, password: string, role_id: any): Promise<void> {
    setAddUserLoading(true);
    try {
      if (password === "" || password === null || email === "" || email === null || role_id === "" || role_id === null) {
        toast.error("Email, password & role cannot be empty")
        setAddUserLoading(false);
      } else {
        const checkPermission = role_id && (roles?.find((item: any) => item?.id == role_id)?.permissions ? JSON.parse(roles?.find((item: any) => item?.id == role_id)?.permissions) : [])
        if (checkPermission?.length > 0) {
          const res: any = await addUserAdminAlt(firstName, lastName, email, phone, password, role_id);
          console.log("resresres", res)
          if (res?.data?.data?.status == 403) {
            setAddUserLoading(false);
            toast.error(res?.data?.data?.message)
            return;
          }
          await refreshUsers();
          toast.success("User added to organization");
          setAddUserLoading(false);
          onCloseModal();
        } else {
          toast.error("Please Select Permissions First")
          setAddUserLoading(false);
        }
      }
    } catch (e) {
      setAddUserLoading(false);
      HandleUIError(e);
    }
  }

  function onCloseModal() {
    setFirstName("");
    setLastName("");
    setUserEmail("");
    setPhone("");
    closeModal();
  }
  const fetch = async () => {
    let data: any = await searchParents(0, 1, '')
    console.log('userSlice?.user?.is_organization_manager', userSlice?.user?.isOrganizationManager)
    if (userSlice?.user?.RoleId) {
      setRoles(data?.data)
    } else {
      // const newdata: any = data?.data?.filter((item: any) => (item?.role_name.toLowerCase() != 'staff'))
      // console.log('newData', newdata)
      setRoles(data?.data)
    }

  }

  useEffect(() => {
    fetch()
  }, []);

  useEffect(() => {

  }, [])

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Add new User</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="users-container">
          <p className="text-light fs-5 ms-3 pt-3">
            First Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputFirstName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Last Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputLastName}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Email
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputMail}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Select Role
          </p>
          <div className="px-3">

            <select
              className="input-custom form-select"
              value={roleId}
              onChange={(e) => setRoleID(e.target.value)}
            >
              <option value="">Please Select</option>
              {roles?.map((item: any) => (
                <>
                  {console.log('los', item)}
                  <option value={`${item?.id}`}>{item?.role_name} {item?.role_type ? `(${item?.role_type})` : ''}</option>
                </>
              ))}
            </select>
          </div>

          <p className="text-light fs-5 ms-3 pt-3">
            Phone
          </p>
          <PhoneInput
            value={phone}
            className="ms-3 me-4 mt-2 bg-transparent "
            onChange={(e: any) => { setPhone(e) }}
          />

          {/* <p className="text-light fs-5 ms-3 pt-3">
              Phone
            </p>
            <Input
              type="number"
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputPhone}
            />   */}
          <p className="text-light fs-5 ms-3 pt-3">
            Password
          </p>
          <Input
            type="password"
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputPassword}
          />
          <Button
            disabled={addUserLoading}
            className="mt-7 button-add px-4 mb-3"
            onClick={() => {
              addUser(firstName, lastName, userEmail, phone, password, roleId)
            }}
          >
            {!addUserLoading ? "Add" : <Spinner />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewUser;

import React, { useEffect, useState } from 'react'
import { Input, PageContainer } from '../Atomics'
import toast from 'react-hot-toast'
// import { getProfile, updatePassword, updateProfile } from '../../services/EditProfile'

const EditProfile = () => {

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    const [confirmPass, setConfirmPassword] = useState("")
    const [newPass, setNewPass] = useState("")
    const [currPass, setCurrPass] = useState("")

    // const handlePass = async (e) => {
    //     e.preventDefault()
    //     const data = { old_password: currPass, password: newPass }
    //     if (newPass?.length < 6) {
    //         return toast.error("Password length must be 6 charachter")
    //     }
    //     if (newPass != confirmPass) {
    //         return toast.error("Password doesn't match")
    //     } else {
    //         try {
    //             const res = await updatePassword(data)
    //             if (res?.data?.status === "success") {
    //                 toast.success(res?.data?.message)
    //                 setCurrPass("")
    //                 setConfirmPassword("")
    //                 setNewPass("")
    //             } else {
    //                 toast.error(res?.data?.message)
    //                 setCurrPass("")
    //                 setConfirmPassword("")
    //                 setNewPass("")
    //             }
    //         } catch (error) {
    //             toast.error(error)
    //         }
    //     }
    // }

    // const handleGetProfile = async () => {
    //     const res = await getProfile()
    //     if (res?.status === 200) {
    //         setFirstName(res?.data?.firstName)
    //         setLastName(res?.data?.lastName)
    //         setEmail(res?.data?.email)
    //     }
    // }

    // const handleEditProfile = async (e) => {
    //     e.preventDefault()
    //     const data = {
    //         first_name: firstName,
    //         last_name: lastName,
    //     }
    //     const res = await updateProfile(data)

    //     if (res?.data?.status === "success") {
    //         toast.success(res?.data?.message)
    //         setFirstName("")
    //         setLastName("")
    //         handleGetProfile()
    //     } else {
    //         toast.error(res?.message)
    //     }
    // }


    // useEffect(() => {
    //     handleGetProfile()
    // }, [])

    return (
        <div className="bg-main h-100 overflow-auto pb-3">
            <div className="">
                <PageContainer className="pb-5 d-flex flex-column p-0">
                    <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
                        <p className="text-light fs-5">Profile Setting</p>
                    </div>



                    <div className="container">
                        <div className="row justify-content-center pb-5 h-100 mt-4">
                            <div className="col-md-5 pb-5">
                                <form>
                                    <div class="form-group mb-3">
                                        <p className="text-light mb-2">First Name</p>
                                        <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="w-100" placeholder="First Name" />
                                    </div>

                                    <div class="form-group mb-3">
                                        <p className='text-light mb-2'>Last Name</p>
                                        <Input type="text"
                                            value={lastName} onChange={(e) => setLastName(e.target.value)}
                                            className="w-100" placeholder="Last Name" />
                                    </div>

                                    <div class="form-group mb-3">
                                        <p className='text-light mb-2'>Email </p>
                                        <Input
                                            value={email}
                                            type="email" className="w-100"
                                            placeholder="Email"
                                            disabled />
                                    </div>

                                    <button
                                        // onClick={handleEditProfile}
                                        type="submit"
                                        class="btn btn-primary w-100">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </PageContainer>

                <PageContainer className="pb-5 d-flex flex-column p-0">
                    <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
                        <p className="text-light fs-5">Change Password</p>
                    </div>


                    <div className="container">
                        <div className="row pb-5 justify-content-center h-100 mt-4">

                            <div className="col-md-5  pb-5" >

                                <form>

                                    <div class="form-group mb-3">
                                        <p className="text-light mb-2">Current Password</p>
                                        <Input
                                            value={currPass}
                                            onChange={(e) => setCurrPass(e.target.value)}
                                            type="password"
                                            className="w-100"
                                            placeholder="Current Password" />
                                    </div>

                                    <div class="form-group mb-3">
                                        <p className='text-light mb-2'>New Password</p>
                                        <Input
                                            value={newPass}
                                            onChange={(e) => setNewPass(e.target.value)}
                                            type="password"
                                            className="w-100"
                                            placeholder="New Password" />
                                    </div>

                                    <div class="form-group mb-3">
                                        <p className='text-light mb-2'>Confirm Password </p>
                                        <Input
                                            value={confirmPass}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            type="password"
                                            className="w-100"
                                            placeholder="Re-Type Password"
                                        />
                                    </div>

                                    <button type="submit"
                                        //  onClick={handlePass} 
                                        class="btn btn-primary w-100">Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </PageContainer>
            </div>
        </div>
    )
}

export default EditProfile
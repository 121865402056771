import * as React from "react";
import { Button } from "../Atomics";
import { useHistory } from "react-router-dom";
import { getUserStatistics } from "../../services/staffService";
import { useEffect, useState } from "react";

const BatteryStatus: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<any>(null)


  function goStaff() {
    history.push("/staff");
  }


  const fetch = async () => {
    const res: any = await getUserStatistics();
    console.log('res==|', res)
    setData(res)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch();
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="new-staff-container battery-status-container w-100 mt-2">
      <div className="new-template-header  d-flex justify-content-between align-items-center">
        <p className="text-light py-2 ps-4 fs-5">Battery Status</p>
      </div>
      <div className="ms-4 ps-3 pt-3 d-flex justify-content-between pe-5">
        <p>Devices with low battery:</p>
        <p className="me-5 fw-bold">
          {data?.low_batteries}
        </p>
      </div>
      <div className="ms-4 ps-3 pt-3 d-flex justify-content-between pe-5">
        <p>Non-Responsive devices:</p>
        <p className="me-5 fw-bold">0</p>
      </div>
      <div className="d-flex ms-4 ps-3 mt-3 pb-4">
        <Button className="me-3 px-4">Low Battery Devices</Button>
        <Button className="px-4" onClick={goStaff}>
          Show All
        </Button>
      </div>
    </div>
  );
};

export default BatteryStatus;

import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { Input, PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Roles } from "../components/types";
// services
import { getEmergencyAlertParent, getParents, searchAmergencyAlert, searchParents } from "../services/groupAlertService";
import GroupList from "../components/groupAlert/GroupList";
import NewRole from "../components/groupAlert/NewRole";
import useDebounce from "../hooks/useDebounce";
import MapModal from "../components/MapModal"
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cilCalendar } from "@coreui/icons";


const EmergencyAlert: React.FC = () => {
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [visibleMap, setVisibleMap] = useState(false)
  const [isEdit, setIsEdit] = useState<any>(null)
  const [currentParents, setCurrentParents] = React.useState<Array<Roles>>([]);
  const [parents, setParents] = React.useState<Array<Roles>>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [parentsPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbers, setPageNumbers] = React.useState<number>(1);
  const [visibleCalendarSince, setVisibleCalendarSince] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [visibleCalendarUntil, setVisibleCalendarUntil] = React.useState(false);
  const [lastDate, setLastDate] = React.useState<Date | null>(new Date());


  const [alertType, setAlertType] = useState<any>('')
  const [search, setSearch] = useState<any>('')
  const MAX_SIZE_ORGANIZATIONS = 10;
  const [numberOfPages, setNumberOfPages] = React.useState(0);

  const debouncedSearch = useDebounce(searchValue, 300);

  // function searchParentsDebounced() {
  //   searchAmergencyAlert(parentsPageSize, 1, searchValue)
  //     .then((parentsFound) => {
  //       setCurrentPage(1);
  //       // @ts-ignore
  //       setCurrentParents(parentsFound.data);
  //       setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //       setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //     })
  //     .catch((e) => {
  //       HandleUIError(e);
  //     });
  // }

  // React.useEffect(
  //   function () {
  //     if (debouncedSearch) {
  //       searchParentsDebounced();
  //     } else {
  //       searchAmergencyAlert(parentsPageSize, 1, searchValue)
  //         .then((parentsFound) => {
  //           setCurrentPage(1);
  //           // @ts-ignore
  //           setCurrentParents(parentsFound.data);
  //           setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //           setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //         })
  //         .catch((e) => {
  //           HandleUIError(e);
  //         });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [debouncedSearch]
  // );

  const reFetch = (limit: any, page: any, search?: any, type?: any) => {
    setLoading(true)
    getEmergencyAlertParent(limit, page, search, type, startDate?.toISOString(), lastDate?.toISOString())
      .then((res: any) => {
        setCurrentParents(res?.rows);
        setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, '', alertType)
    if (search.trim()?.length > 2) {
      reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
    } else {
      // Clear results if query is empty
      setCurrentParents([]);
    }
  }, [search, startDate, lastDate])

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, [alertType, startDate, lastDate]);

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, [currentPage, startDate, lastDate]);

  React.useEffect(function () {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, [startDate, lastDate]);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  console.log("currentParents", currentParents);

  //this function is treggered by pagination
  async function refreshParents() {
    setLoading(true);
    try {
      let parentsFound: any = await searchAmergencyAlert(
        parentsPageSize,
        currentPage,
        searchValue
      );
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  const setMapData = (data: any) => {
    setIsEdit(data)
    setVisibleMap(true)
  }

  function toggleCalendarSince() {
    setVisibleCalendarSince(!visibleCalendarSince);
  }

  function toggleCalendarUntil() {
    setVisibleCalendarUntil(!visibleCalendarUntil);
  }

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
        <div className="d-flex flex-custom-row flex-column staff-menu w-100">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="col-auto">
              <h3 className="text-light me-auto mb-4">Emergency Alert</h3>
            </div>
            <div className="col-lg-8">
              <div className="row justify-content-end align-items-end">
                <div className="col-lg-4">
                  <select
                    onChange={(e: any) => setAlertType(e.target.value)}
                    value={alertType}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">Please Select</option>
                    <option value="Voice">
                      Voice
                    </option>
                    <option value="Image">
                      Image
                    </option>
                    <option value="Message">
                      Message
                    </option>
                  </select>
                </div>
                <div className="col-lg-4">
                  <Input
                    type="search"
                    className="w-100 small pe-2"
                    placeholder="Search By Name"
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>



            <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap d-none">
              <div
                className="add-icon-container mg-lt add-icon-staff"
                onClick={onPressAdd}
              >
                <CIcon icon={cilPlus} size="xl" className="add-icon" />
              </div>
            </div>
          </div>

        </div>

        <div className="row mb-3 justify-content-end">


          <div className="col-auto">
            {/* {listSettings.list && ( */}
            <div className="d-flex d-md-custom-flex justify-content-custom w-100">
              <div className="d-flex mg-r">
                <p className="d-flex text-light align-items-center mg-r-xs">
                  Since:
                </p>
                <div
                  className="calendar-icon-container cursor-pointer mt-1"
                  onClick={toggleCalendarSince}
                >
                  <CIcon
                    size="xl"
                    icon={cilCalendar}
                    className="text-light"
                  />
                </div>
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  onClickOutside={toggleCalendarSince}
                  onInputClick={toggleCalendarSince}
                  open={visibleCalendarSince}
                  className="calendar-input mt-1"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    toggleCalendarSince();
                    toggleCalendarUntil();
                  }}
                />
              </div>
              <div className="d-flex">
                <p className="d-flex text-light align-items-center mg-r-xs">
                  Until:
                </p>
                <div
                  className="calendar-icon-container cursor-pointer mt-1"
                  onClick={toggleCalendarUntil}
                >
                  <CIcon
                    size="xl"
                    icon={cilCalendar}
                    className="text-light"
                  />
                </div>
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  onClickOutside={toggleCalendarUntil}
                  onInputClick={() => {
                    setVisibleCalendarSince(false);
                    toggleCalendarUntil();
                  }}
                  open={visibleCalendarUntil}
                  className="calendar-input mt-1"
                  selected={lastDate}
                  minDate={startDate}
                  onChange={(date) => {
                    setLastDate(date);
                    toggleCalendarUntil();
                  }}
                />
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div>
          <GroupList
            parents={currentParents}
            loading={loading}
            refreshParents={refreshParents}
            alert={'Emergency'}
            setEditData={setMapData}
          />
        </div>
        <div className="py-3">
          {numberOfPages > 1 ? (
            <Pagination
              numberOfPages={numberOfPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          ) : (
            <></>
          )}
        </div>
      </PageContainer>
      <NewRole
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={refreshParents}
      />
      <NewMessage />

      {visibleMap && (
        <MapModal
          visible={visibleMap}
          closeModal={() => setVisibleMap(false)}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default EmergencyAlert;

import * as React from "react";
import "./Sidebar.scss";
import { Link } from "react-router-dom";
import {
  CSidebar,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CSidebarHeader,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilHouse,
  cilUser,
  cilEnvelopeClosed,
  cilBell,
  cilAddressBook,
  cilSpeedometer,
  cilSettings,
  cilPeople,
} from "@coreui/icons";
import { useLocation } from "react-router-dom";
import { RiAdminLine, RiAlarmWarningFill, RiAlarmWarningLine } from "react-icons/ri";
import { FiActivity, FiUserCheck } from "react-icons/fi";
import { MdOutlineContactEmergency } from "react-icons/md";

import { VscBellDot } from "react-icons/vsc";

// images
import gcLogoHoriz from "../assets/gc_logo_powby_horiz_white.png";
import Settings from "../views/Settings";
import { getOrganizationInfo } from "../services/loggedUserService";
import HandleUIError from "../utils/HandleUIError";
import { handlerDrillMode } from "../services/drillService";
import { saveOrganization } from "../redux/reducers/organizationReducer";
import { useAppSelector } from "../redux/hooks";
import { store } from "../redux/store";
import usePermission from "../hooks/usePermission";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';


interface Props {
  visible: boolean;
}

const SideBar: React.FC<Props> = ({ visible }) => {
  const { checkPermission } = usePermission()
  const location = useLocation();
  const [pathName, setPathName] = React.useState<string>();
  const selectedPathColorClass: string = "bg-dark-sidebar";
  const [visibleSettings, setVisibleSettings] = React.useState<boolean>(false);
  const [drillMode, setDrillMode] = React.useState<boolean>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const organizationInfo = useAppSelector((state) => state.organizationSlice);
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  console.log('location.pathname===', location.pathname)
  React.useEffect(
    function () {
      switch (location.pathname) {
        case "/":
          setPathName("Dashboard");
          break;
        case "/facility":
          setPathName("Facility");
          break;
        case "/staff":
          setPathName("Staff");
          break;
        case "/messages":
          setPathName("Messages");
          break;
        case "/setup-alerts":
          setPathName("Setup Alerts");
          break;
        case "/alerts":
          setPathName("Alerts");
          break;
        case "/parents":
          setPathName("Parents");
          break;
        case "/roles":
          setPathName("Roles");
          break;
        case "/group-alert":
          setPathName("Group-Alert");
          break;
        case "/emergency-contact":
          setPathName("Emergency-Contact");
          break;
        case "/emergency-contact-relation":
          setPathName("Emergency-Contact-Relation");
          break;
        case "/alarm-alerts":
          setPathName("Alarm Alerts");
          break;
        case "/app-user":
          setPathName("App Users");
          break;
        case "/activity-logs":
          setPathName("Activity Logs");
          break;
        case "/admins":
          setPathName("Admins");
          break;
        case "/emergency-alert":
          setPathName("Emergency Alert");
          break;
        default:
          setPathName("");
          break;
      }
      setIsLoading(true);
      getOrganizationInfo()
        .then((res) => {
          store.dispatch(saveOrganization(res));
          setIsLoading(false);
        })
        .catch((e) => {
          HandleUIError(e);
          setIsLoading(false);
        });
    },
    [location.pathname, drillMode]
  );

  function OnCloseModal() {
    setVisibleSettings(!visibleSettings);
  }

  async function handleDrillMode() {
    setIsLoading(true);
    try {
      await handlerDrillMode(!drillMode);
      setDrillMode(!drillMode);
      setIsLoading(false);
    } catch (e) {
      HandleUIError(e);
      setIsLoading(false);
    }
  }

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  return (
    <>
      <CSidebar visible={visible} className="h-100">
        <CSidebarHeader className="logo">
          <Link to="/">
            <img
              src={gcLogoHoriz}
              alt="GuardianCall powered by LutiBand"
              className="img-fluid"
            />
          </Link>
        </CSidebarHeader>
        <CSidebarNav className="bg-dark1 d-flex justify-content-between">
          <div>
            {organizationInfo ? (
              <div className="text-decoration-none fw-bold p-3 text-black bg-light ">
                {organizationInfo.name}
              </div>
            ) : null}
            {(checkPermission('View Quick Alert') ||
              checkPermission('Send Quick Alert') ||
              checkPermission('Send Group Message') ||
              checkPermission('Save School Details')
            ) && (
                <Link to="/" className="text-decoration-none text-light">
                  <div
                    className={`d-flex pt-3 ${pathName === "Dashboard" ? selectedPathColorClass : ""
                      }`}
                  >
                    <CIcon icon={cilSpeedometer} className="me-3 ms-3 mt-1" />
                    <p className="pb-3">Dashboard</p>
                  </div>
                </Link>
              )}
            <CNavTitle>OPTIONS & CONFIGURATION</CNavTitle>
            {checkPermission('View Facility') && (
              <Link className="text-decoration-none text-light" to="/facility">
                <CNavItem
                  href="#"
                  className={
                    pathName === "Facility" ? selectedPathColorClass : ""
                  }
                >
                  <CIcon icon={cilHouse} className="me-3" />
                  Facility
                </CNavItem>
              </Link>
            )}
            {(checkPermission('View Staff') || checkPermission('View Staff')) && (
              <Link className="text-decoration-none text-light" to="/staff">
                <CNavItem
                  href="#"
                  className={pathName === "Staff" ? selectedPathColorClass : ""}
                >
                  <CIcon icon={cilPeople} className="me-3" />
                  Staffs
                </CNavItem>
              </Link>
            )}
            {checkPermission('View Message') && (
              <Link className="text-decoration-none text-light" to="/messages">
                <CNavItem
                  href="#"
                  className={
                    pathName === "Messages" ? selectedPathColorClass : ""
                  }
                >
                  <CIcon icon={cilEnvelopeClosed} className="me-3" />
                  Messages
                </CNavItem>
              </Link>
            )}
            {(checkPermission('View History') ||
              checkPermission('View Settings') ||
              checkPermission('View Categories')
            ) && (
                <Link className="text-decoration-none text-light" to="/setup-alerts?single=0">
                  <CNavItem
                    href="#"
                    className={pathName === "Setup Alerts" ? selectedPathColorClass : ""}
                  >
                    <CIcon icon={cilBell} className="me-3" />
                    Setup Alerts
                  </CNavItem>
                </Link>
              )}


            <div className="text-decoration-none cursor-pointer text-light" onClick={toggleSubMenu}>
              <div className="d-flex pt-3 justify-content-between align-items-center pb-3">
                <div className="d-flex">

                  <CIcon icon={cilSpeedometer} className="me-3  ms-3 mt-1" />
                  <p className=" mb-0">User Alerts</p>
                </div>
                {/* Conditional Arrow Icon */}
                <div>

                  {isSubMenuOpen ? (
                    <FaChevronUp className="ms-2 me-3" />
                  ) : (
                    <FaChevronDown className="ms-2 me-3" />
                  )}
                </div>
              </div>
            </div>

            {/* Sub-Modules - Visible only if dropdown is open */}
            {isSubMenuOpen && (
              <div className="ps-3">
                <Link className="text-decoration-none text-light" to="/emergency-alert">
                  <CNavItem
                    href="javascript:void(0)"
                    className={
                      pathName === "Emergency Alert" ? selectedPathColorClass : ""
                    }
                  >
                    {/* <CIcon icon={cilUser} className="me-3" /> */}
                    <VscBellDot className="me-3" />

                    Emergency Alerts
                  </CNavItem>
                </Link>

                <Link className="text-decoration-none text-light" to="/alerts?single=1">
                  <CNavItem
                    href="javascript:void(0)"
                    className={
                      pathName === "Alerts" ? selectedPathColorClass : ""
                    }
                  >
                    {/* <CIcon icon={cilUser} className="me-3" /> */}
                    <VscBellDot className="me-3" />

                    Other Alerts
                  </CNavItem>
                </Link>
              </div>
            )}

            {checkPermission('View Parents') && (
              <Link className="text-decoration-none text-light" to="/parents">
                <CNavItem
                  href="#"
                  className={
                    pathName === "Parents" ? selectedPathColorClass : ""
                  }
                >
                  <CIcon icon={cilAddressBook} className="me-3" />
                  Parents
                </CNavItem>
              </Link>
            )}
            {checkPermission('View Roles') && (
              <Link className="text-decoration-none text-light" to="/roles">
                <CNavItem
                  href="#"
                  className={pathName === "Roles" ? selectedPathColorClass : ""}
                >
                  <CIcon icon={cilAddressBook} className="me-3" />
                  Roles
                </CNavItem>
              </Link>
            )}

            {checkPermission('View Admin') && (
              <Link className="text-decoration-none text-light" to="/admins">
                <CNavItem
                  href="#"
                  className={pathName === "Admins" ? selectedPathColorClass : ""}
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <RiAdminLine className="me-3" />

                  Admins
                </CNavItem>
              </Link>
            )}
            {checkPermission('View App Users') && (
              <Link className="text-decoration-none text-light" to="/app-user">
                <CNavItem
                  href="#"
                  className={pathName === "App Users" ? selectedPathColorClass : ""}
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <FiUserCheck className="me-3" />

                  App Users
                </CNavItem>
              </Link>
            )}

            <Link className="text-decoration-none text-light" to="/activity-logs">
              <CNavItem
                href="#"
                className={pathName === "Activity Logs" ? selectedPathColorClass : ""}
              >
                {/* <CIcon icon={cilUser} className="me-3" /> */}
                <FiActivity className="me-3" />

                Activity Logs
              </CNavItem>
            </Link>

            {checkPermission('View Alarm Alerts') && (
              <Link className="text-decoration-none d-none text-light" to="/alarm-alerts">
                <CNavItem
                  href="#"
                  className={pathName === "Alarm Alerts" ? selectedPathColorClass : ""}
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <RiAlarmWarningLine className="me-3" />


                  Alarm Alerts
                </CNavItem>
              </Link>
            )}
            {checkPermission('View Group Alerts') && (
              <Link className="text-decoration-none text-light" to="/group-alert">
                <CNavItem
                  href="#"
                  className={
                    pathName === "Group-Alert" ? selectedPathColorClass : ""
                  }
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <VscBellDot className="me-3" />

                  Group Message
                </CNavItem>
              </Link>
            )}


            {checkPermission('View Emergency Contact') && (
              <Link
                className="text-decoration-none text-light"
                to="/emergency-contact"
              >
                <CNavItem
                  href="#"
                  className={
                    pathName === "Emergency-Contact" ? selectedPathColorClass : ""
                  }
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <MdOutlineContactEmergency className="me-3" />

                  Emergency Contact
                </CNavItem>
              </Link>
            )}
            {checkPermission('View Emergency Contact') && (
              <Link
                className="text-decoration-none text-light"
                to="/emergency-contact-relation"
              >
                <CNavItem
                  href="#"
                  className={
                    pathName === "Emergency-Contact-Relation" ? selectedPathColorClass : ""
                  }
                >
                  {/* <CIcon icon={cilUser} className="me-3" /> */}
                  <MdOutlineContactEmergency className="me-3" />

                  Contacts Relation
                </CNavItem>
              </Link>
            )}
            {/* <CNavItem
          href="#"
          className={pathName === "Social Media" ? selectedPathColorClass : ""}
        >
          <CIcon icon={cilShareAlt} className="me-3" />
          Social Media
        </CNavItem> */}
          </div>
          <div>
            <CNavItem
              onClick={() => setVisibleSettings(true)}
              href="#"
              className={pathName === "Settings" ? selectedPathColorClass : ""}
            >
              <CIcon icon={cilSettings} className="me-3" />
              Settings
            </CNavItem>
          </div>
        </CSidebarNav>
        <div className="form-switch d-none d-flex pt-3 bg-dark2">
          <p className="me-5 mb-3">Drill Mode</p>
          {isLoading ? (
            <div className="ms-5">
              <CSpinner size="sm" />
            </div>
          ) : (
            <input
              className="form-check-input ms-5"
              checked={organizationInfo.isDrillMode}
              type="checkbox"
              role="switch"
              onChange={handleDrillMode}
            />
          )}
        </div>
      </CSidebar>
      <Settings visible={visibleSettings} closeModal={OnCloseModal} />
    </>
  );
};

export default SideBar;

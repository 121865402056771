import AWS from 'aws-sdk';

AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
        accessKeyId: 'AKIA5FCD6TPPKMTOAJOM',
        secretAccessKey: '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',
    }),
});

const s3 = new AWS.S3({
    params: { Bucket: 'callguardian' },
    region: 'us-east-1',
});

export default s3;




import * as React from "react";
import "./NewTemplate.scss";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

interface Props {
  onChangeFile: (file: any) => void;
}

const acceptedTypes = [".wav",".WAV"];

const DropZone: React.FC<Props> = ({ onChangeFile }) => {
  const onDrop = React.useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    //validate that the file is in .amr format
    if (acceptedFile) {
      const fileType = acceptedFile.name.substring(
        acceptedFile.name.lastIndexOf(".")
      );
      if (acceptedTypes.includes(fileType)) {
        onChangeFile(acceptedFile);
      } else {
        toast.error("Please upload an .wav file");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()} className="drop-zone-container">
      <input {...getInputProps()} />
      <p className="py-2">
        {isDragActive
          ? "Drop the file here ..."
          : "Drag and drop a .wav audio file here, or click to select file"}
      </p>
    </div>
  );
};

export default DropZone;

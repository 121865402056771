import React, { useEffect, useState } from 'react';
import { IconButton, MenuHeader, PageContainer } from "../components/Atomics";
import { cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
import { appApproveStatus, appUserStatus, getAppUsers } from "../services/appUserService";
import { CSpinner } from "@coreui/react";
import MapModal from "../components/MapModal";
import moment from "moment";
import { Pagination } from "../components";
import usePermission from "../hooks/usePermission";
import { createActivityReq } from "../services/activityLogService";

const AppUser = () => {
    const { checkPermission } = usePermission()
    const [users, setUsers] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [offSet, setOffSet] = useState<any>(0)
    const [order, setOrder] = useState<any>("DESC")
    const [searchInput, setSearchInput] = useState<any>('')
    const [nextDisabled, setNextDisabled] = useState(false)
    const MAX_SIZE_ORGANIZATIONS = 10;
    const [numberOfPages, setNumberOfPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);



    const handleStatus = (data: any) => {
        setLoadingStatus(true)
        console.log('!data.status', !data.active)
        const body = {
            status: !data.active
        }
        appUserStatus(data?.id, body)
            .then(async (res: any) => {
                console.log('status1', res)
                setLoadingStatus(false)
                getUsers(currentPage)
                await createActivityReq('App User Status Changed Successfully')
            })
            .catch((e: any) => {
                HandleUIError(e);
                setLoadingStatus(false)
            });
    }

    const handleApproveStatus = (data: any) => {
        setLoadingStatus(true)
        console.log('!data.status', !data.is_approve)
        const body = {
            status: !data.is_approve
        }
        appApproveStatus(data?.id, body)
            .then(async (res: any) => {
                console.log('status1', res)
                setLoadingStatus(false)
                getUsers(currentPage)
                await createActivityReq('App User Approval Changed Successfully')
            })
            .catch((e: any) => {
                HandleUIError(e);
                setLoadingStatus(false)
            });
    }

    const getUsers = (currentPage = 0, search = '', order = '') => {
        setLoading(true)
        getAppUsers(currentPage, searchInput, order)
            .then((res: any) => {
                console.log('users1', res)
                setUsers(res?.rows);
                setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
                setLoading(false)
            })
            .catch((e: any) => {
                HandleUIError(e);
                setLoading(false)
            });
    }

    console.log('numberofPaages', numberOfPages)

    const handleNext = async (count: any) => {
        setLoading(true);
        const offSet: any = count + 10
        setOffSet(offSet)
        // getUsers(offSet)
    }

    const handlePrev = async (count: any) => {
        setLoading(true);
        const offSet: any = count - 10
        setOffSet(offSet)
        // getUsers(offSet)
    }

    useEffect(() => {
        getUsers(currentPage)
    }, [currentPage])

    useEffect(() => {
        getUsers();

        if (searchInput.trim()?.length > 2) {
            getUsers(offSet, searchInput);
        } else {
            // Clear results if query is empty
            setUsers([]);
        }
    }, [searchInput]);

    useEffect(() => {
        getUsers(offSet, searchInput, order)
    }, [order])

    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">
                            <h3 className="text-white mb-0">App Users</h3>
                        </div>

                        <div className="col-2 ms-auto">
                            <select
                                className="form-control shadow-none text-white"
                                style={{
                                    backgroundColor: '#38373d',
                                    border: '2px solid #44444c',
                                }}
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            >
                                <option value="DESC">Descending Order</option>
                                <option value="ASC">Ascending Order</option>
                            </select>
                        </div>
                        <div className="col-auto">
                            <div className="wrap mg-bot mg-lt center-text mb-0">
                                <p className="text-light me-2">Search:</p>
                                <input
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    className="input-search"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="col-auto">
                            <button
                                className=" btn btn-primary position-relative shadow-none"
                                onClick={() => getUsers()}
                            >Refresh </button>
                        </div>
                    </div>
                    <div className="overflowX">
                        <>
                            <MenuHeader className="d-flex pt-3 pb-3 px-2 justify-content-around app-user-table-size">
                                <p className="text-color col d-none d-sm-block">
                                    Name
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Student ID
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Email
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Phone No
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Role
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Location
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Status
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Approved Users
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Last Register
                                </p>
                                {/*<p className="text-color section-width-parents-4 d-none d-sm-block"></p>*/}
                            </MenuHeader>
                            {loading ? (
                                <div className="d-flex justify-content-center mt-4">
                                    <CSpinner variant="grow" />
                                </div>
                            ) : (
                                <>
                                    {users?.length > 0 ? (
                                        <>
                                            {users.map((item: any) => (
                                                <div key={item?.id} className="app-user-table-size d-flex py-3 px-2 user-information-container justify-content-around">
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >{item?.firstName} {''} {item?.lastName}</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >{item?.student_id} </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >{item?.email}</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >{item?.phone}</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >{item?.role?.role_name}</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p
                                                            className="text-light word-break"
                                                            role="button"
                                                            onClick={() => setIsEdit(item)}
                                                        >
                                                            {checkPermission("View App User Location, Approval and Status") && (
                                                                <button className="btn btn-sm btn-primary"
                                                                    style={{ fontSize: "0.8rem" }}
                                                                >
                                                                    View Location
                                                                </button>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {checkPermission("View App User Location, Approval and Status") && (
                                                                <>
                                                                    {item?.active ? (
                                                                        <button
                                                                            style={{ fontSize: "0.8rem" }}
                                                                            className="btn btn-sm btn-success text-white py-1 px-4"
                                                                            onClick={() => handleStatus(item)}
                                                                        >
                                                                            Active
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            style={{ fontSize: "0.8rem" }}
                                                                            className="btn btn-sm btn-danger text-white px-4"
                                                                            onClick={() => handleStatus(item)}
                                                                        >
                                                                            Deactive
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {checkPermission("View App User Location, Approval and Status") && (
                                                                <>
                                                                    {item?.is_approve ? (
                                                                        <button
                                                                            style={{ fontSize: "0.8rem" }}
                                                                            className="btn btn-sm btn-success text-white py-1 px-4"
                                                                            onClick={() => handleApproveStatus(item)}
                                                                        >
                                                                            Approved
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn btn-sm btn-danger text-white px-3"
                                                                            onClick={() => handleApproveStatus(item)}
                                                                        >
                                                                            Unapproved
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break"
                                                            style={{ fontSize: "0.8rem" }}
                                                        >
                                                            {moment(item?.createdAt).format('DD-MM-YY / HH:MM')}
                                                        </p>
                                                    </div>
                                                    <div className="section-width-parents-4 d-flex justify-content-end align-items-center d-none">
                                                        <div className="margin-icon">
                                                            <IconButton
                                                                icon={cilPencil}
                                                                size="lg"
                                                            // onClick={() => onPressAdd()}
                                                            />
                                                        </div>
                                                        <div>
                                                            <IconButton
                                                                icon={cilTrash}
                                                                size="lg"
                                                                className="ms-1"
                                                            // onClick={() => removeUser(parent.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : <h5 className="text-white text-center py-2">DATA NOT FOUND</h5>}
                                </>
                            )}
                        </>
                    </div>

                    <div className="py-3">
                        {numberOfPages > 1 ? (
                            <Pagination
                                numberOfPages={numberOfPages}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        ) : (
                            <></>
                        )}
                    </div>


                </PageContainer>
            </div>


            {Boolean(isEdit) && (
                <MapModal
                    visible={Boolean(isEdit)}
                    closeModal={() => setIsEdit(null)}
                    isEdit={isEdit}
                    zoom={14}
                />
            )}

        </>
    );
};

export default AppUser;
import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Parents } from "../components/types";

interface PaginationResponse {
  data: Array<Parents>;
  numberOfTotalParents: number;
}

export async function searchParents(
  limit: number,
  page: number,
  search: string
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(
      `/admin/contacts?limit=${limit}&page=${page}&freeText=${search}`
    );
    const data = response.data.data as Array<Parents>;
    return { data, numberOfTotalParents: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getParents(
  limit: number,
  page: number
): Promise<Array<Parents>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(`/admin/contacts?limit=${limit}&page=${page}`);
    return response.data.data as Array<Parents>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function saveParents(name: string, email: string | null, phone: string | null): Promise<void> {
  try {
    const body = {
      name,
      email,
      phone
    };
    await axiosConfig.post("admin/contacts", body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
export async function saveNewParents(firstName: string, lastName: string, relationId: string, studentId: string, email: string | null, phone: string | null): Promise<void> {
  try {
    const body = {
      first_name: firstName,
      last_name: lastName,
      relation_id: relationId,
      user_id: studentId,
      email,
      phone
    };
    await axiosConfig.post("admin/all-emergency-contacts", body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function removeContactFromParents(
  parentId: number
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `/admin/contacts/${parentId}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editContactFromParents(
  parentId: number,
  name: string,
  email: string | null,
  phone: string | null
): Promise<Array<any>> {
  try {
    const body = {
      role_name: name,
      email,
      phone
    };
    const response = await axiosConfig.patch<AxiosResponse>(
      `/admin/contacts/${parentId}`,
      body
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}